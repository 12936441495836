.wrapperSignUpModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wrapperTitle {
  padding: var(--indent-base);
  margin-bottom: var(--indent-base);
}

.textTitle {
  font-size: 1rem;
  margin-bottom: var(--indent-base);
  color: var(--sign-up-modal-bonus-text-title-color);
}

.subText {
  font-size: 0.8rem;
  color: var(--sign-up-modal-bonus-subtext-color);
  margin-bottom: var(--indent-base);
}

.bonusText {
  margin-bottom: var(--indent-base);
  font-size: 1.2rem;
  color: var(--sign-up-modal-bonus-text-color);
  padding: var(--indent-base);
  text-align: center;
  font-weight: 700;
}

.wrapperTabsAndForm {
  width: 100%;
  padding: 0 var(--indent-base);
}

.zIndex {
  position: relative;
  z-index: 3;
}

.tabs {
  margin-bottom: calc(var(--indent-base) * 2);
}

.marginB {
  margin-bottom: var(--indent-base);
}

.dash {
  width: calc(var(--full) - var(--indent-base) * 2);
  height: 2px;
  background: var(--sign-up-modal-dash-background-color);
  margin-bottom: var(--indent-base);
}

.wrapperSignUpModal .signIn {
  margin-bottom: var(--indent-base);
  opacity: 0.7;
  width: calc(var(--full) - var(--indent-base) * 2);
  max-width: calc(var(--full) - var(--indent-base) * 2);
  min-width: calc(var(--full) - var(--indent-base) * 2);
}

.checkbox {
  margin-bottom: var(--indent-base);
  color: var(--sign-up-modal-checkbox-color);
}

.link {
  text-decoration: underline;
}


.promoTitle {
  text-align: center;
  color: var(--promo-code-modal-text);
  font-size: var(--font-size-xs);
}

.wrapperReferralFailure {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .wrapperSignUpModal {
    width: 500px;
  }
}