:root {
  --white: 255, 255, 255;
  --primary: 254, 206, 0;
  --third: 0, 0, 0;
  --red: 255, 80, 80;
  --semi-gray: 118, 128, 163;
  --gray: 81, 88, 113;
  --dark-gray: 33, 36, 51;
  --semi-dark: 242, 242, 247;
  --dark: 39, 40, 53;
  --light-gray: 39, 43, 58;

  --full: 100%;
  --large: 300px;
  --medium: 200px;
  --small: 130px;
  --super-small: 80px;

  --border-color-gray: rgba(var(--soft-gray));

  --indent-base: 12px;
  /* indents */
  --indent-xs: 4px;
  --indent-s: 8px;
  --indent-m: 12px;
  --indent-l: 16px;
  --indent-xl: 20px;
  --indent-xxl: 24px;
  --indent-xxxl: 28px;
  /* borders */
  /* border-radius */
  --border-xs: 4px;
  --border-s: 8px;
  --border-m: 12px;
  --border-l: 16px;
  --border-xl: 20px;
  --border-xxl: 24px;
  --border-xxxl: 28px;
  --border-circle: 50%;
  /* font-sizes */
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 20px;
  --font-size-xl: 22px;
  --font-size-xxl: 24px;
  --font-size-huge: 40px;

  --kgames-gradient-yellow: linear-gradient(to bottom, #ffa600, #ffe47b);
  --kgams-orange: #ca8619;
  --kgams-yellow: #e9d317;
  --drawer-full-width: 320px;
  --drawer-min-width: 100px;

  --whee-gradient-small-circle: radial-gradient(
    circle at 50% 50%,
    rgb(var(--primary)),
    rgba(var(--semi-dark), 0)
  );

  --scrollbar-track-background-color: rgb(var(--white));

  /* Referral */
  --referral-bonus-background: rgba(233, 233, 235);
  --referral-text-color: rgb(var(--dark));
  --referral-title-color: rgb(var(--dark));
  --referral-banner-title-color: rgb(var(--dark));
  --referral-banner-subtitle-color: rgb(var(--dark));
  --referral-details-border-color: rgb(var(--soft-gray));
  --referral-card-color: rgb(var(--semi-gray));
  --referral-sub-title-color: rgb(var(--semi-gray));

  /* UI_KIT_BASE */
  --small-button-height: 30px;
  --button-height-step: 3px;
  --button-color-primary-text: rgb(var(--third));

  /* Events */
  --base-event-height: 90px;
  /* Ticket */
  --ticket-header-height: 40px;
  --ticket-ordinar-height: 82px;
  --ticket-stake-info: 55px;
  /* End ticket */

  --oddWidth: clamp(45px, 13vh, 45px);
  --oddHeight: clamp(45px, 13vh, 45px);
  --oddMarginLeft: 5px;

  /* TODO only here - variable.js doesn't have this variables */
  --lottery-ticket-height: 45px;
  --lottery-ticket-height-button: 15px;
  --lottery-ticket-width: 85px;

  /* ================== Input Base ================== */
  --input-base-autofill-background-color: transparent;
  /*  */
  --input-base-autofill-text-color: rgb(var(--third));
  --input-base-autofill-label-text-color: rgb(var(--gray));
  --input-base-row-children-data-svg-path-fill: rgb(135, 135, 139);
  --base-input-clear-button-background: rgba(198, 198, 200, 1);
  --base-input-clear-button-svg-path: rgba(246, 246, 246, 1);
  /* ================== end ================== */

  /* ================== Modal ================== */
  --modal-black-background-color: rgba(var(--third), 0.7);
  --modal-layout-background-color: rgba(233, 233, 235, 1);
  /* ================== end ================== */

  /* ================== InGameTickets ================== */
  --ingame-tickets-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== BonusInfo ================== */
  --vip-club-bonuses-progress-wrapper-color: rgb(var(--white));
  --vip-club-bonuses-progress-wrapper-row-color: rgb(var(--semi-gray));
  --vip-club-bonuses-weekly-bonus-info-background-color: rgb(var(--dark));
  --vip-club-bonuses-weekly-bonus-info-title-box: rgb(var(--white));
  --vip-club-bonuses-vip-progress-wrapper-background-color: rgb(
    var(--dark-gray)
  );
  --vip-club-bonuses-block-text-color: rgb(var(--white));
  --vip-club-bonuses-border-background-color: rgb(var(--dark-gray));
  --vip-club-bonuses-border-color: rgb(var(--third));
  --vip-club-bonuses-border-text-color: rgba(var(--white), 0.8);
  /* ================== end ================== */

  /* ================== GeneralBonuses ================== */
  --general-bonuses-title-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== AvatarBox ================== */
  --avatar-box-background-color: rgb(var(--avatar-gray-bg));
  --avatar-box-desktop-background-color: linear-gradient(
    90deg,
    #0b0c11 0%,
    #272b3a 100%
  );
  --avatar-box-expand-content-background-color: rgb(var(--avatar-gray-bg));
  --avatar-box-wrapper-content-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== VipBonuses ================== */
  --vip-bonuses-progress-background-color: rgba(var(--soft-gray));
  --vip-bonuses-progress-title-color: rgb(var(--third));
  --vip-bonuses-progress-title-opacity-color: rgba(var(--third), 0.7);
  --vip-bonuses-progress-down-text-color: rgb(var(--third));
  --vip-bonuses-content-text-color: rgb(var(--semi-gray));
  --vip-bonuses-content-text-title-color: rgb(var(--white));
  --vip-bonuses-content-text-title-background-color: rgb(var(--white));
  --vip-bonuses-content-text-arrow-color-fill: rgb(var(--white));
  /* ================== end ================== */

  /* ================== HowToJoin ================== */
  --how-to-join-content-background-color: rgb(var(--semi-dark));
  --how-to-join-content-title-background-color: -webkit-linear-gradient(
      229deg,
      rgb(var(--primary)),
      rgba(0, 6, 62, 0)
    ),
    linear-gradient(157deg, rgb(var(--third), 0.4), rgba(255, 179, 30, 0)),
    #ff8921;
  --how-to-join-content-special-offer-background-color: linear-gradient(
      229deg,
      #340844,
      rgba(0, 6, 62, 0)
    ),
    linear-gradient(157deg, rgb(var(--third), 0.4), rgba(255, 179, 30, 0)),
    #ff8921;
  --how-to-join-special-offer-title-color: rgb(var(--white));
  --how-to-join-special-offer-description-color: rgb(var(--semi-dark));
  --how-to-join-ways-background-color: rgb(var(--white));
  --how-to-join-ways-description-color: rgb(var(--semi-gray));
  --how-to-join-way-name-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== AdditionalPerks ================== */
  --perk-background-color: rgb(var(--semi-dark));
  --perk-title-color: rgb(var(--primary));
  --perk-descriptions-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== HighestBonuses ================== */
  --highest-bonus-wrapper-background-color: rgb(var(--semi-dark));
  --highest-bonus-grow-title-color: rgb(var(--third));
  --highest-bonus-name-color: rgb(var(--primary));
  --highest-bonus-description-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== PersonalSupport ================== */
  --personal-support-title-color: rgb(var(--white));
  --personal-support-item-color: rgb(var(--gray));
  --personal-support-vip-list-background-color: rgb(var(--semi-dark));
  --personal-support-vip-wrapper-background-color: rgb(var(--white));
  --personal-support-vip-wrapper-avatar-color: rgb(var(--primary));
  --personal-support-vip-wrapper-avatar-description-color: rgb(
    var(--semi-gray)
  );
  /* ================== end ================== */

  /* ================== SpecialTreats ================== */
  --special-treats-tab-content-item-background-color: rgb(var(--semi-dark));
  --special-treats-tab-content-item-description-block-color: rgb(var(--gray));
  --special-treats-tab-content-item-description-title-color: rgb(
    var(--primary)
  );
  /* ================== end ================== */

  /* ================== VipClubDescriptions ================== */
  --vip-club-descriptions-background-color: linear-gradient(
    360deg,
    rgb(var(--semi-dark)) 60%,
    rgba(var(--semi-dark), 0) 100%
  );
  --vip-club-descriptions-title-color: rgb(var(--dark));
  --vip-club-descriptions-subtitle-color: rgb(var(--dark));
  --vip-club-benefit-wrapper-after-background-color: rgb(203, 110, 0);
  --vip-club-shadow-tabs-before-background-color: linear-gradient(
    90deg,
    rgb(var(--semi-dark)),
    rgba(13, 19, 28, 0)
  );
  --vip-club-shadow-tabs-after-background-color: linear-gradient(
    270deg,
    rgb(var(--semi-dark)),
    rgba(13, 19, 28, 0)
  );
  --vip-club-tab-color: rgb(var(--white));
  --vip-club-tab-name-color: rgb(var(--dark));
  --vip-club-active-tab-color: rgb(203, 110, 0);
  --vip-club-yellow-active-tab-color: rgb(var(--third));
  --vip-club-active-tab-wrapper-image-background-color: radial-gradient(
    circle,
    #ffcc6a 5%,
    rgba(var(--primary), 0) 80%
  );
  --vip-club-active-tab-description-color: rgb(var(--white));
  --vip-club-active-tab-before-border-color: transparent transparent #cb6e00
    transparent;
  --vip-club-active-tab-after-border-color: transparent transparent transparent
    #cb6e00;
  --vip-club-tab-content-background-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== VipClubRulesBanner ================== */
  --vip-club-banner-background-color: radial-gradient(
      50% 50% at 50% 50%,
      rgba(254, 171, 8, 0.5) 0,
      rgba(248, 182, 53, 0) 100%
    ),
    radial-gradient(
      49.51% 27.4% at 52.73% 12.25%,
      rgb(var(--white)) 0,
      #ffcc6a 34.38%,
      rgba(255, 201, 62, 0) 100%
    ),
    linear-gradient(180deg, #cb6e00, rgba(35, 17, 44, 0)),
    linear-gradient(
      180deg,
      rgb(var(--third)),
      #92350e 31.58%,
      #611d2e 49.32%,
      #340844 74.88%,
      rgb(var(--dark))
    );
  --vip-club-banner-color: rgb(var(--white));
  --vip-club-banner-title-color: rgb(var(--dark-gray));
  /* ================== end ================== */

  /* ================== VipClubRules ================== */
  --vip-club-rules-background-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Bonuses ================== */
  --bonuses-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== BonusPack ================== */
  --bonus-pack-modal-background-color: rgb(var(--semi-dark));
  --bonus-pack-modal-title-color: rgb(var(--white));
  --bonus-pack-modal-subtitle-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== BuyTicketResultPopUp ================== */
  --buy-ticket-result-pop-up-background-color: rgb(var(--semi-dark));
  --buy-ticket-result-pop-up-go-back-button-background-color: rgb(var(--dark));
  --buy-ticket-result-pop-up-header-title-color: rgb(var(--semi-gray));
  --buy-ticket-result-pop-up-content-description-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== BuyLotteryTicket ================== */
  --buy-lottery-ticket-go-back-button-background-color: rgb(242, 242, 247);
  --buy-lottery-ticket-image-background-color: linear-gradient(
    102.35deg,
    #d4bbff -8.18%,
    #8a3ffc 84.59%
  );
  --buy-lottery-ticket-desktop-background-color: rgba(var(--semi-dark), 0.6);
  --buy-lottery-ticket-desktop-wrapper-background-color: rgba(
    var(--semi-dark),
    1
  );
  --buy-lottery-ticket-image-red-banner-background-color: rgba(
    102,
    195,
    255,
    1
  );
  --buy-lottery-ticket-image-red-banner-path-color: rgb(216, 73, 87);
  --buy-lottery-ticket-subtitle-color: rgb(var(--white));
  --buy-lottery-ticket-subtitle-span-color: rgb(var(--primary));
  --buy-lottery-ticket-presets-title-color: rgb(var(--gray));
  --buy-lottery-ticket-presets-tickets-count-color: rgb(var(--primary));
  --buy-lottery-ticket-presets-button-background-color: rgb(248, 248, 248);
  --buy-lottery-ticket-presets-button-minus-background-color: rgb(
    var(--primary)
  );
  --buy-lottery-ticket-presets-button-plus-background-color: rgb(
    var(--primary)
  );
  --buy-lottery-ticket-total-titles-color: rgb(var(--gray));
  --buy-lottery-ticket-total-sum-color: rgb(var(--primary));
  --buy-lottery-ticket-info-wrapper-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== CalendarModal ================== */
  --calendar-modal-wrapper-background-color: rgb(var(--white));
  --calendar-modal-background-color: rgb(var(--white));
  --calendar-modal-color: rgb(var(--gray));
  --calendar-modal-active-color: rgb(var(--primary));
  --calendar-modal-navigation-button-color: rgb(var(--gray));
  --calendar-modal-tile-color: rgba(var(--gray), 0.3);
  --calendar-modal-tile-now-color: rgba(var(--gray), 0.5);
  /* ================== end ================== */

  /* ================== AviatrixBonusOnDep ================== */
  --aviatrix-bonus-on-dep-background-gradient-color: var(
    --gradient-bonus-free-spin
  );
  --aviatrix-bonus-on-dep-background-color: rgb(var(--semi-dark));
  --aviatrix-bonus-on-dep-title-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== AviatrixSpins ================== */
  --aviatrix-spins-background-gradient-color: var(--gradient-bonus-free-spin);
  --aviatrix-spins-background-color: rgb(var(--semi-dark));
  --aviatrix-spins-title-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== CasinoFavorites ================== */
  --casino-favorites-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== ProviderBlock ================== */
  --provider-block-letter-color: rgb(var(--gray));
  --provider-box-background: rgb(var(--dark));
  /* ================== end ================== */

  /* ================== ProviderItem ================== */
  --provider-item-row-background-color: rgba(255, 255, 255, 1);
  --provider-item-row-name-color: rgba(0, 0, 0, 1);
  --provider-item-row-value-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== ProviderList ================== */
  --provider-list-scrollbar-track-background-color: rgb(var(--dark-gray));
  --provider-list-scrollbar-thumb-background-color: rgb(var(--primary));
  --provider-list-scrollbar-thumb-hover-background-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== ProviderSearch ================== */
  --provider-search-icon-input-path-fill: rgb(var(--gray));
  --provider-search-close-icon-path-fill: rgb(var(--semi-dark-gray));
  /* ================== end ================== */

  /* ================== CasinoTournamentPlayers ================== */
  --casino-tournament-players-background-color: rgb(var(--dark));
  /* ================== end ================== */

  /* ================== TournamentRules ================== */
  --casino-tournament-rules-background-color: rgb(var(--semi-dark));
  --casino-tournament-rules-content-background-color: rgb(var(--semi-dark));
  --casino-tournament-rules-block-point-color: rgb(var(--third));
  --casino-tournament-rules-block-rule-text-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== ModalCasinoTournament ================== */
  --modal-casino-tournament-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== JackpotInfo ================== */
  --jackpot-info-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== Credentials ================== */
  --credentials-background-color: rgb(var(--semi-dark));
  --credentials-info-username-color: rgb(var(--gray));
  --credentials-info-result-color: rgb(var(--dark));
  /* ================== end ================== */

  /* ================== DrawerModal ================== */
  --drawer-modal-content-color: rgb(var(--semi-dark));
  --drawer-modal-content-text-color: rgb(var(--third));
  --drawer-modal-content-background: rgba(0, 0, 0, 0.4);
  /* ================== end ================== */

  /* ================== InstaNews Dash ================== */
  --dash-filled-background-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== InstaNews Post ================== */
  --post-end-background-color: var(--gradient-gray);
  --post-end-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== InstaNews SlideProgress ================== */
  --slide-progress-background-color: rgba(var(--launch-game-background), 0.4);
  --slide-progress-dash-background-color: rgba(var(--white), 0.5);
  --slide-progress-active-dash-background-color: rgb(var(--white));
  --slide-progress-info-box-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Languages ================== */
  --languages-drawer-background-color: rgb(var(--white));
  --languages-drawer-close-button-background-color: rgb(var(--soft-gray));
  /* ================== end ================== */

  /* ================== LootBoxGame ================== */
  --loot-box-game-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== LostPassword ================== */
  --lost-password-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== MoneyOperations ================== */
  --money-operations-wallet-forms-background-color: rgb(var(--semi-dark));
  --money-operations-wallet-forms-content-background-color: rgb(
    var(--semi-dark)
  );
  --money-operations-wallet-forms-header-background: rgb(var(--semi-dark));
  --money-operations-wallet-forms-presets-background: rgba(135, 135, 139);
  --money-commission-text-color: rgba(135, 135, 139);
  /* ================== end ================== */

  /* ================== MoneyOperations BonusContent ================== */
  --bonus-content-background-color: linear-gradient(
    187deg,
    #0cb989 15.88%,
    #1c5d7c 95.31%
  );
  --bonus-content-bonus-name-color: rgb(var(--white));
  --bonus-content-bonus-min-deposit-color: rgba(var(--white), 0.5);
  --bonus-content-bonus-offer-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== MoneyOperations BonusSlideArrow ================== */
  --bonus-slider-arrow-slider-button-background-color: rgb(var(--semi-dark));
  --bonus-slider-arrow-slider-button-svg-path-fill: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== MoneyOperations AvailableBonuses ================== */
  --available-bonuses-bonus-checkbox-text-color: rgba(var(--dark), 1);
  /* ================== end ================== */

  /* ================== BKash  ================== */
  --bkash-bdt-info-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== InrDefaultDeposit ================== */
  --inr-default-deposit-text-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== Nagad ================== */
  --nagad-deposit-bdt-info-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== QrForm ================== */
  --qr-form-deposit-title-color: rgb(var(--third));
  --qr-form-info-min-text-color: rgb(135, 135, 139);
  --qr-form-info-min-text-span-color: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== Rocket ================== */
  --rocket-bdt-info-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== TurkishAnindaHavale ================== */
  --turkish-aninda-havale-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== TurkishCepBank ================== */
  --turkish-cep-bank-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== TurkishHavaleHera ================== */
  --turkish-havale-hera-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== TurkishMefete ================== */
  --turkish-mefete-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== TurkishMpayMethods ================== */
  --turkish-mpay-methods-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== TurkishPayFix ================== */
  --turkish-pay-fix-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== VipHavale ================== */
  --vip-havale-checkbox-descriptions-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== AvailableForWithdraw ================== */
  --available-for-withdraw-color: rgb(var(--third));
  --available-for-withdraw-title-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== InrWithdraw ================== */
  --inr-withdraw-subtitle-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ==================TurkishAnindaHavale withdraw  ================== */
  --turkish-aninda-havale-withdraw-checkbox-descriptions-color: rgb(
    var(--white)
  );
  /* ================== end ================== */

  /* ================== MoneyOperations Forms List ================== */
  --list-item-wrapper-background-color: rgb(var(--soft-gray));
  --list-item-wrapper-color: rgb(var(--semi-gray));
  --list-fiat-channel-name-background-color: rgb(var(--white));
  --list-crypto-subtitle-color: rgb(var(--third));
  --list-fiat-default-image-background-color: rgb(var(--white));
  --list-fiat-default-image-path-fill: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== PaymentMessage ================== */
  --payment-message-info-background-color: rgb(var(--semi-dark));
  --payment-message-info-color: rgb(var(--gray));
  --payment-message-info-title-status-color: rgb(var(--dark));
  --payment-message-info-title-qr-code-color: rgb(var(--white));
  --payment-message-info-title-qr-code-span-color: rgb(var(--primary));
  --payment-message-info-text-wrapper-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== Withdraw Methods ================== */
  --withdraw-methods-title-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== PaymentFrame ================== */
  --payment-frame-background-color: rgb(var(--semi-dark));
  --payment-frame-close-button-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== Withdraw Tabs ================== */
  --withdraw-tabs-button-modifier-color: rgb(135, 135, 139);
  --withdraw-tabs-active-text-color: rgb(var(--third));
  --withdraw-tabs-active-text-path-fill: rgb(var(--third));
  --withdraw-tabs-active-tab-background-color: rgba(233, 233, 235, 1);
  --withdraw-tabs-active-tab-border-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== SetNewPassword ================== */
  --set-new-password-background-color: rgb(var(--semi-dark));
  --set-new-password-title-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== SignIn ================== */
  --sign-in-modal-background-color: rgb(var(--semi-dark));
  --sign-in-modal-lost-password-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== SignUp ================== */
  --sign-up-modal-background-color: rgb(var(--semi-dark));
  --sign-up-modal-bonus-text-color: rgb(var(--primary));
  --sign-up-modal-bonus-text-title-color: rgb(var(--primary));
  --sign-up-modal-bonus-subtext-color: rgb(var(--gray));
  --sign-up-modal-dash-background-color: var(--border-color-gray);
  --sign-up-modal-checkbox-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== TransactionDetails ================== */
  --transaction-details-background-color: rgb(var(--semi-dark));
  --transaction-details-row-color: rgb(var(--gray));
  --transaction-details-row-value-color: rgba(var(--dark), 0.8);
  --transaction-details-link-color: rgb(var(--third));
  --transaction-details-link-border-color: rgb(var(--third));
  --transaction-details-value-green-color: rgb(var(--primary));
  --transaction-details-value-red-color: rgb(var(--red));
  /* ================== end ================== */

  /* ================== TransactionFilter ================== */
  --transaction-filter-background-color: rgb(var(--semi-dark));
  --transaction-filter-transaction-name-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== NotificationView ================== */
  --notification-wrapper-background-color: rgb(var(--white));
  --notification-text-color: rgb(var(--gray));
  --notification-close-icon-background-color: rgb(var(--semi-dark));
  --notification-close-icon-path-fill: var(--border-color-gray);
  /* ================== end ================== */

  /* ================== AppNotify ================== */
  --app-notify-border-color: rgb(var(--soft-gray));
  --app-notify-background-color: rgb(var(--white));
  --app-notify-box-shadow-color: rgba(0, 0, 0, 0.24);
  --app-notify-text-color: rgb(var(--gray));
  --app-notify-title-color: rgb(var(--gray));
  --app-notify-subtitle-color: rgb(var(--semi-gray));
  --app-notify-logo-background-color: rgb(var(--white));
  --app-notify-logo-border-color: rgb(var(--primary));
  --app-notify-close-button-svg-path-fill: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== BETTING ================== */
  --banner-title-color: rgba(var(--third), 1);
  /* ================== END ================== */
  /* ================== BETTING ================== */

  /* ================== Express ================== */
  --express-color: rgb(var(--white));
  --express-bet-count: rgb(var(--third));
  /* ================== end ================== */

  /* ================== BetSlipContent ================== */
  --bet-slip-content-background-color: rgb(var(--semi-dark));
  --bet-slip-content-odd-wrapper-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== Ordinar ================== */
  --ordinar-info-odd-value-color: rgb(var(--primary));
  --ordinar-error-status-background-color: rgb(var(--red));
  --ordinar-accepted-status-background-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== System ================== */
  --system-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== EventInfo ================== */
  --event-info-name-color: rgb(var(--white));
  --event-info-market-name-color: rgb(var(--third));
  --event-info-team-names-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== MaxBet ================== */
  --max-bet-sum-wrapper-color: rgb(var(--gray));
  --max-bet-error-color: rgb(var(--red));
  /* ================== end ================== */

  /* ================== EventInfo ================== */
  --event-info-wrapper-background-color: rgb(var(--dark));
  --event-info-header-wrapper-color: rgb(var(--semi-gray));
  --event-info-content-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== MainScore ================== */
  --main-score-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== Times ================== */
  --time-wrapper-color: rgb(var(--semi-gray));
  --times-number-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== Indicator ================== */
  --indicator-green-animation-background-color: rgb(var(--primary));
  --indicator-red-animation-background-color: rgb(var(--red));
  /* ================== end ================== */

  /* ================== Odd ================== */
  --odd-background-color: rgb(var(--color-dark));
  --odd-color: rgb(var(--white));
  --odd-selected-border-color: rgba(var(--third), 0.8);
  --odd-selected-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== SportFilterMarkets ================== */
  --sport-filter-markets-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== Sports ================== */
  --sports-text-color: rgb(var(--white));
  --sports-active-sport-icon-path-fill: rgb(var(--primary));
  --sports-sport-wrapper-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== LinesByMarket ================== */
  --lines-by-market-odd-wrapper-color: rgb(var(--white));
  --lines-by-market-start-text-classes-color: rgb(var(--semi-gray));
  --lines-by-market-special-value-color: rgb(var(--semi-dark));
  --lines-by-market-sname-lines-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== MarketTitleName ================== */
  --market-title-name-left-side-wrapper-color: rgba(var(--white), 0.8);
  /* ================== end ================== */

  /* ================== BaseMarketView ================== */
  --base-market-view-color: rgb(var(--white));
  --base-market-view-background-color: rgb(var(--dark));
  /* ================== end ================== */

  /* ================== EventInfoOdds ================== */
  --events-info-odds-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== ShortEventAdditionalLines ================== */
  --show-move-lines-background-color: rgb(var(--dark));
  --short-event-additional-line-name-color: rgba(var(--white), 0.8);
  /* ================== end ================== */

  /* ================== PredRospisTournamentsTable ================== */
  --header-category-tournament-events-background-color: rgb(var(--dark-gray));
  /* ================== end ================== */

  /* ================== PredRospisTable ================== */
  --pred-rospis-table-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== CashoutRecords ================== */
  --cashout-records-visible-history-title-color: rgb(var(--white));
  --cashout-records-cashout-history-item-title-color: rgb(var(--white));
  --cashout-records-cashout-history-item-value-color: rgb(var(--white));
  --cashout-records-cashout-history-item-time-block-color: rgb(
    var(--semi-gray)
  );
  /* ================== end ================== */

  /* ================== BONUS CABINET ================== */

  /* ================== Tabs ================== */
  --bonus-pack-active-tab-background-color: var(--gradient-green);
  --tab-text-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== Casino Loyalty ================== */
  /* ================== Frame ================== */
  --frame-loader-background-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== Navigation ================== */
  --navigation-button-color: rgb(var(--white));
  --navigation-bar-height: 60px;
  --navigation-active-route-border-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== CASINO COMPONENTS ================== */
  /* ================== ProviderTournament ================== */
  --provider-tournament-priz-pool-background-color: rgb(var(--dark));
  --provider-tournament-priz-pool-color: rgb(var(--primary));
  --provider-tournament-provider-name-color: rgb(var(--gray));
  --provider-tournament-finished-tournament-color: rgb(var(--third));
  --provider-tournament-open-provider-games-color: rgb(var(--gray));
  --provider-tournament-black-container-background-color: rgba(
    var(--semi-dark),
    0.8
  );
  --provider-tournament-rules-background-color: rgb(var(--semi-dark));
  --provider-tournament-title-color: rgb(var(--gray));
  --provider-tournament-text-color: rgb(var(--white));
  --provider-tournament-arrow-background-color: rgba(var(--semi-dark), 0.8);
  --provider-tournament-arrow-path-fill: rgb(var(--white));
  /* ================== end ================== */

  /* ================== CasinoProviderTournaments ================== */
  --provider-tournaments-slider-info-wrapper-color: rgb(var(--third));
  --provider-tournaments-slider-info-wrapper-background-color: rgb(
    var(--semi-dark)
  );
  /* ================== end ================== */

  /* ================== CasinoCategories ================== */
  --casino-categories-content-background-color: rgb(var(--semi-dark));
  --casino-categories-arrow-button-border-color: rgb(var(--soft-gray));
  --casino-categories-arrow-button-background-color: rgb(var(--semi-dark));
  --casino-categories-fill-star-path-fill: rgb(var(--third));
  --casino-categories-have-fav-game-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== CasinoCategory ================== */
  --casino-category-text-color: rgb(var(--third));
  --casino-category-button-background-color: rgba(233, 233, 235, 1);
  --casino-active-category-border-color: rgb(var(--third));
  --casino-category-button-icon-path-fill: rgb(135, 135, 139);
  --casino-active-category-button-icon-path-fill: rgb(var(--primary));
  --casino-active-category-button-background-color: rgb(var(--soft-gray));
  --casino-category-in-house-background-color: rgba(233, 233, 235, 1);
  --casino-category-in-house-active-button-background-color: rgb(
    var(--soft-gray)
  );
  --casino-category-in-house-svg-g-path-fill: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== RowGames ================== */
  --row-casino-games-show-all-button-background-color: rgb(var(--dark-gray));
  --row-casino-games-show-all-button-color: #000;
  /* ================== end ================== */

  /* ================== CasinoHomeRowHeader ================== */
  --casino-home-row-header-category-icon-background-color: transparent;
  --casino-home-row-header-category-icon-path-fill: rgb(var(--third));
  --casino-home-row-header-category-name-color: rgba(var(--third), 1);
  --casino-home-row-header-category-show-all-button-color: rgb(135, 135, 139);
  --casino-home-row-header-category-arrow-more-path-fill: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== CasinoGame ================== */
  --casino-game-background-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== BalanceBlock ================== */
  --balance-block-color: rgb(var(--third));
  --balance-wrapper-border: 2px solid var(--border-color-gray);
  /* ================== end ================== */

  /* ================== LaunchGame ================== */
  --launch-game-background-color: rgb(var(--launch-game-background));
  --launch-game-row-center-background-color: rgb(var(--semi-dark));
  --launch-game-play-text-color: rgb(var(--third));
  --launch-game-game-image-background-color: rgb(var(--launch-game-background));
  /* ================== end ================== */

  /* ================== DesktopLaunchGamePopUp ================== */
  --desktop-launch-game-pop-up-game-frame-background-color: rgba(
    var(--third),
    0.7
  );
  --desktop-launch-game-pop-up-games-wrapper-background-color: rgb(
    var(--white)
  );
  --desktop-launch-game-pop-up-additional-info-background-color: rgb(
    var(--semi-dark)
  );
  --desktop-launch-game-pop-up-svg-background-color: rgb(var(--semi-dark));
  --desktop-launch-game-pop-up-svg-path-fill: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== MobileLaunchGamePopUp ================== */
  --mobile-launch-game-pop-up-game-frame-background-color: rgba(
    var(--semi-dark),
    0.6
  );
  /* ================== end ================== */

  /* ================== ProviderName ================== */
  --provider-name-background-color: rgba(var(--dark), 0.8);
  --provider-name-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== CasinoProviderSelector ================== */
  --casino-provider-selector-item-styles-color: rgb(var(--gray));
  --casino-provider-selector-main-item-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== RecommendedGames ================== */
  --recommended-games-game-title-color: rgb(var(--third));
  --recommended-games-svg-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== RightSideToggler ================== */
  --right-side-toggler-background-color: rgba(var(--white), 1);
  --right-side-toggler-active-tab-background-color: rgb(233, 233, 235);
  --right-side-toggler-border-color: rgb(var(--primary));
  --right-side-toggler-color: initial;
  /* ================== end ================== */

  /* ================== GameActions ================== */
  --game-actions-close-button-background-color: rgb(var(--soft-gray));
  --game-actions-close-button-svg-path-fill: rgb(var(--white));
  /* ================== end ================== */

  /* ================== CasinoHomeRowHeader (ProviderRow) ================== */
  --provider-row-header-category-icon-background-color: rgb(var(--dark-gray));
  --provider-row-header-category-icon-path-fill: rgb(var(--third));
  --provider-row-header-category-name-color: rgba(var(--white), 0.7);
  --provider-row-header-category-show-all-button-color: rgb(var(--third));
  --provider-row-header-category-arrow-more-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== CountDown ================== */
  --count-down-background-color: rgba(var(--white), 0.15);
  --count-down-time-block-color: rgba(var(--dark), 1);
  --count-down-time-info-color: rgba(var(--third), 0.5);
  --count-down-time-card-block-background-color: rgb(var(--white));
  --count-down-item-dot-background-color: rgb(var(--gray));
  --count-down-day-name-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== BackHeader ================== */
  --back-header-sport-color: rgb(var(--gray));
  --back-header-sport-background-color: rgb(var(--dark-gray));
  /* ================== end ================== */

  /* ================== CategoryTournamentList ================== */
  --category-tournament-list-background-color: rgb(var(--semi-dark));
  --category-tournament-wrapper-tournament-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== CategoryInfoBySport ================== */
  --category-info-by-sport-background-color: rgb(var(--dark-gray));
  --category-info-by-sport-category-name-color: rgb(var(--white));
  --category-info-by-sport-table-header-color: rgb(var(--gray));
  --category-info-by-sport-table-header-category-name-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== ListSports ================== */
  --list-sports-active-sport-border-color: rgba(var(--primary), 0.6);
  --list-sports-active-sport-svg-path-fill: rgba(var(--primary), 1);
  /* ================== end ================== */

  /* ================== BettingDrawer ================== */
  --betting-drawer-tabs-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== AppSwitcher ================== */
  --app-switcher-background-color: rgb(var(--white));
  --app-switcher-link-text-color: rgb(var(--third));
  --app-switcher-active-nav-link-background-color: rgb(var(--soft-gray));
  --app-switcher-link-new-svg-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== BonusButtons ================== */
  --bonus-buttons-active-button-background-color: rgb(var(--gray));
  --bonus-buttons-bonus-count-red-background-color: rgba(var(--primary), 1);
  --bonus-buttons-svg-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== CasinoButtons ================== */
  --casino-buttons-active-button-background-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== CasinoProviderButtons ================== */
  --casino-provider-buttons-active-button-background-color: rgb(var(--gray));
  --casino-provider-svg-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== DrawerDocs ================== */
  --drawer-docs-button-color: rgb(var(--gray));
  --drawer-docs-svg-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== SocialButtons ================== */
  --social-buttons-button-background-color: transparent;
  /* ================== end ================== */

  /* ================== CasinoDrawer ================== */
  --casino-drawer-dash-background-color: rgb(var(--soft-gray));
  --casino-drawer-button-language-background-color: transparent;
  --casino-drawer-icon-green-path-fill: rgb(var(--primary));
  --casino-drawer-fav-items-color: rgb(var(--gray));
  --casino-drawer-email-color: rgb(var(--white));
  --casino-drawer-email-background-color: rgb(var(--soft-gray));
  --casino-drawer-jivo-button-background-color: transparent;
  --casino-drawer-icon-path-fill: rgb(var(--third));
  --casino-drawer-vip-club-path-fill: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== Docs ================== */
  --docs-accordion-wrapper-color: rgba(var(--third), 1);
  /* ================== end ================== */

  /* ================== Footer ================== */
  --footer-background-color: rgb(var(--white));
  --footer-doc-title-color: rgb(var(--semi-gray));
  --footer-text-block-color: rgb(var(--gray));
  --footer-license-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== Header ================== */
  --header-background-color: rgba(242, 242, 247, 1);
  --header-desktop-background-color: rgb(var(--white));
  --header-jivo-button-background-color: transparent;
  --header-button-text-color: rgb(var(--white));
  --header-arrow-back-path-fill: rgba(var(--third), 0.7);
  --header-jivo-border: var(--balance-wrapper-border);
  /* ================== end ================== */

  /* ================== AuthButtons ================== */
  --auth-buttons-text-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== HeaderLeftSection ================== */
  --header-left-section-background-color: rgb(var(--white));
  --header-left-section-toggler-svg-path-stroke: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== ProfileInfo ================== */
  --profile-info-profile-button-background-color: rgb(var(--soft-gray));
  --profile-info-balance-wrapper-background-color: rgb(246, 246, 246);
  --profile-info-balance-text-color: rgb(var(--third));
  --profile-info-balance-arrow: rgba(var(--white), 0.6);
  --profile-info-wallet-button-background-color: rgba(39, 195, 92, 1);
  --profile-info-balance-arrow: rgba(135, 135, 139, 1);
  --profile-info-wallet-button-background-color: rgb(var(--primary));
  --profile-info-wallet-button-deposit-icon: rgb(var(--white));
  --profile-info-wallet-button-border-color: rgb(var(--primary));
  --profile-info-portal-balance-wrapper-background-color: rgba(
    var(--third),
    0.7
  );
  --profile-info-balance-svg-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== ProfileInfo BalanceList  ================== */
  --balance-list-balance-info-background-color: rgb(var(--semi-dark));
  --balance-list-balance-title-color: rgb(var(--gray));
  --balance-list-balance-value-color: rgb(var(--third));
  --balance-list-bonus-active-balance-background-color: rgb(var(--soft-gray));

  /* ================== end ================== */

  /* ================== ProfileInfo DesktopProfile ================== */
  --desktop-profile-view-profile-icon-background-color: rgb(var(--white));
  --desktop-profile-view-profile-content-color: rgb(var(--gray));
  --desktop-profile-view-profile-content-username-color: rgba(var(--third), 1);
  /* ================== end ================== */

  /* ================== HOCRequestLoader ================== */
  --hoc-request-loader-background-color: rgba(var(--semi-dark), 0.5);
  --hoc-request-loader-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== JACKPOTS ================== */
  /* ================== JackpotCounter ================== */
  --jackpot-counter-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== JackpotFeatures ================== */
  --jackpot-features-title-color: rgb(var(--white));
  --jackpot-features-content-background-color: linear-gradient(
    180deg,
    rgb(var(--semi-dark)),
    rgba(var(--dark), 0.3)
  );
  --jackpot-features-content-feature-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== JackpotRules ================== */
  --jackpot-rules-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== JackpotStep ================== */
  --jackpot-step-background-color: linear-gradient(
    180deg,
    rgb(var(--semi-dark)) 0%,
    rgba(var(--dark), 0.85) 90%
  );
  --jackpot-step-name-color: rgb(var(--third));
  --jackpot-step-hit-range-color: rgba(var(--white), 0.7);
  /* ================== end ================== */

  /* ================== JackpotWinners ================== */
  --jackpot-winners-title-color: rgb(var(--white));
  --jackpot-winners-content-background-color: rgb(var(--semi-dark));
  --jackpot-winners-content-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== JivoChat ================== */
  --jivo-chat-custom-button-color: rgb(var(--white));
  --jivo-chat-support-agent-active-border-color: rgb(var(--primary));
  --jivo-chat-new-message-background-color: rgb(var(--red));
  --jivo-chat-support-svg-path-fill: rgb(135, 135, 139);
  /* ================== end ================== */

  /* ================== LastBigWinTable ================== */
  --last-big-win-table-header-color: rgb(var(--semi-gray));
  --big-win-card-background: rgba(233, 233, 235, 1);
  --big-win-card-label-background: #272b3a;
  --big-win-card-img-width: 110px;
  --big-win-card-text-color: rgba(var(--third), 1);
  --big-win-winner-card-size: 240px;
  --big-win-winner-card-height: 125px;
  --big-win-card-label-background: rgba(155, 35, 248, 1);
  --big-win-profile-icon-fill: rgba(0, 0, 0, 1);

  /* ================== end ================== */

  /* ================== LastBigWin List  ================== */
  --last-big-win-list-game-name-color: rgb(var(--third));

  --last-big-win-list-content-table-background-color: rgb(var(--white));
  --last-big-win-list-win-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== LastBigWin Titles ================== */
  --last-big-win-title-name-color: rgb(var(--third));
  --last-big-win-title-casino-show-more-button-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== BonusCardLayout ================== */
  --bonus-card-layout-title-color: rgb(var(--third));
  --bonus-card-layout-detail-color: rgb(var(--primary));
  --bonus-card-layout-description-color: rgb(var(--third));
  --bonus-card-layout-value-color: rgba(52, 199, 89, 1);
  --bonus-card-layout-timer-background-color: rgba(199, 199, 199, 1);
  /* ================== end ================== */

  /* ================== DesktopLayout ================== */
  --desktop-layout-content-after-background-color: transparent;
  --desktop-layout-desktop-body-background-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== LOTTERY ================== */
  /* ================== HistoryInstantCash ================== */
  --history-instant-cash-background-color: rgb(248, 248, 248);
  --history-instant-cash-wrapper-info-background-color: rgb(var(--semi-dark));
  --history-instant-cash-wrapper-info-desktop-background-color: rgba(
    var(--semi-dark),
    0.6
  );
  --history-instant-cash-wrapper-desktop-background-color: rgba(
    var(--semi-dark),
    1
  );
  --history-instant-cash-details-info-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== HistoryLotteryTicket ================== */
  --history-lottery-ticket-title-color: rgb(var(--semi-gray));
  --history-lottery-ticket-time-color: rgb(var(--gray));
  --history-lottery-ticket-status-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== TicketFullInfo ================== */
  --ticket-full-info-color: rgb(var(--semi-gray));
  --ticket-full-info-value-color: rgb(var(--semi-gray));
  --ticket-full-info-green-value-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== InstantCash ================== */
  --instant-cash-image-wrapper-background-color: rgb(102, 195, 255);
  --instant-cash-image-wrapper-path-fill: rgb(216, 73, 87);
  --instant-cash-waves-styles-path-fill: rgba(var(--white), 0.1);
  --instant-cash-image-wrapper-sub-banner-text-color: rgb(var(--third));
  --instant-cash-title-color: rgb(var(--gray));
  --instant-cash-title-span-color: rgb(var(--primary));
  --instant-cash-progress-left-color: rgb(var(--white));
  --instant-cash-progress-left-span-color: rgb(var(--third));
  --instant-cash-progress-fill-div-background-color: rgb(var(--primary));
  --instant-cash-buy-ticket-button-background-color: linear-gradient(
    180deg,
    rgba(var(--semi-dark), 0.4) 0%,
    rgba(var(--semi-dark), 0.72) 29%
  );
  /* ================== end ================== */

  /* ================== LotteryBanner ================== */
  --lottery-banner-image-wrapper-background-color: linear-gradient(
    102.35deg,
    rgb(212, 187, 255) -8.18%,
    rgb(138, 63, 252) 84.59%
  );
  --lottery-banner-time-background-color: rgba(199, 199, 199, 0.5);
  --lottery-banner-time-color: rgb(var(--third));
  --lottery-banner-win-sum-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== LotteryCard ================== */
  --lottery-card-background-color: rgb(242, 242, 247);
  --lottery-card-status-background-color: rgb(var(--third));
  --lottery-card-status-color: rgb(var(--semi-dark));
  --lottery-card-description-background-color: rgb(var(--white));
  --lottery-card-title-color: rgb(var(--semi-gray));
  --lottery-card-lottery-name-color: rgb(var(--third));
  --lottery-card-lottery-name-span-color: rgb(var(--third));
  --lottery-card-price-color: rgba(52, 199, 89, 1);
  --lottery-card-progress-left-color: rgb(var(--third));
  --lottery-card-progress-left-span-color: rgba(52, 199, 89, 1);
  --lottery-card-progress-fill-div-background-color: rgba(52, 199, 89, 1);
  /* ================== end ================== */

  /* ================== ExpandContent ================== */
  --expand-content-title-color: rgb(var(--gray));
  --expand-content-pages-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== LotteryTicket ================== */
  --lottery-ticket-background-color: rgb(var(--primary));
  --lottery-ticket-before-and-background-color: rgb(248, 248, 248);
  --lottery-ticket-button-background-color: rgba(255, 165, 37, 1);
  --lottery-ticket-button-border-color: rgba(255, 165, 37, 1);
  --lottery-ticket-button-color: rgb(var(--third));
  --lottery-ticket-already-gamed-background-color: rgba(233, 233, 235, 1);
  --lottery-ticket-already-gamed-button-background-color: rgb(var(--primary));
  --lottery-ticket-already-gamed-button-border-color: rgb(var(--primary));
  --lottery-ticket-not-played-button-background-color: rgb(var(--semi-gray));
  --lottery-ticket-not-played-button-border-color: rgb(var(--gray));
  --lottery-ticket-not-played-background-color: rgb(var(--gray));
  --lottery-ticket-played-button-background-color: rgb(var(--gray));
  --lottery-ticket-played-button-border-color: rgb(var(--semi-gray));
  --lottery-ticket-played-background-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== LotteryInstantCard ================== */
  --lottery-instant-card-background-color: rgb(248, 248, 248);
  --lottery-instant-card-left-title-block-color: rgb(var(--gray));
  --lottery-instant-card-left-title-block-span-color: rgba(52, 199, 89, 1);
  --lottery-instant-card-ticket-counter-color: rgb(var(--third));
  --lottery-instant-card--arrow-svg-background-color: rgb(248, 248, 248);
  /* ================== end ================== */

  /* ================== MainLoader ================== */
  --main-loader-background-color: rgb(var(--dark));
  --main-loader-progress-wrapper-background-color: rgb(var(--semi-dark));
  --main-loader-progress-wrapper-border-color: rgba(var(--gray), 0.5);
  --main-loader-progress-background-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== NavigationBar ================== */
  --navigation-bar-background-color: rgba(242, 242, 247, 1);
  --navigation-bar-green-path-fill: rgb(var(--primary));
  --navigation-bar-button-color: rgb(var(--third));
  --navigation-bar-active-nav-background-color: rgb(var(--soft-gray));
  --navigation-bar-active-nav-svg-path-fill: rgb(var(--white));
  --navigation-bar-svg-path-fill: rgb(199, 199, 199);
  /* ================== end ================== */

  /* ==================  PROFILE COMPONENTS ================== */
  /* ==================  AccountForm ================== */
  --account-form-edit-button-border-color: rgba(var(--primary), 0.3);
  /* ================== end ================== */

  /* ================== Email ================== */
  --email-form-title-color: rgb(var(--white));
  --email-error-icon-path-fill: rgb(var(--red));
  --email-error-message-color: rgb(var(--red));
  /* ================== end ================== */

  /* ================== Password ================== */
  --password-form-title-color: rgb(var(--white));
  --password-error-icon-path-fill: rgb(var(--red));
  --password-error-message-color: rgb(var(--red));
  /* ================== end ================== */

  /* ================== PhoneNumber ================== */
  --phone-number-form-title-color: rgb(var(--white));
  --phone-number-error-icon-path-fill: rgb(var(--red));
  --phone-number-error-message-color: rgb(var(--red));
  /* ================== end ================== */

  /* ================== TitleForm ================== */
  --title-form-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== BalanceInfo ================== */
  --balance-info-block-background-color: rgb(var(--semi-dark));
  --balance-info-title-block-color: rgb(var(--semi-gray));
  --balance-info-value-block-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== DesktopUserInfo ================== */
  --desktop-user-info-profile-content-color: rgb(var(--gray));
  --desktop-user-info-profile-content-username-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== ProfileTabs ================== */
  --profile-tabs-navigation-button-color: rgb(var(--third));
  --profile-tabs-active-nav-border-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== UserName ================== */
  --user-name-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== UI ================== */
  /* ================== BonusOnDeposit ================== */
  --bonus-on-deposit-background-color: var(--gradient-bonus-on-deposit);
  --bonus-on-deposit-title-color: rgb(var(--white));
  --bonus-on-deposit-title-details-button-color: rgb(var(--primary));
  --bonus-on-deposit-row-color: rgba(var(--white), 0.5);
  --bonus-on-deposit-row-span-color: rgb(var(--white));
  --bonus-on-deposit-count-down-background-color: rgba(var(--white), 0.7);
  --bonus-on-deposit-count-down-time-div-color: rgba(var(--semi-gray), 1);
  --bonus-on-deposit-count-down-time-color: rgb(10, 10, 10);
  /* ================== end ================== */

  /* ================== ClaimBonus ================== */
  --claim-bonus-info-box-background-color: rgb(var(--dark-gray));
  --claim-bonus-title-color: rgb(var(--white));
  --claim-bonus-rakeback-info-color: rgb(var(--white));
  --claim-bonus-description-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== FreeSpin ================== */
  --freespins-background-color: linear-gradient(
    200.52deg,
    #656ac1 7.44%,
    #373da9 104.15%
  );
  --freespins-info-color: rgba(var(--white), 0.5);
  --freespins-title-color: rgb(var(--white));
  --freespins-row-span-color: rgb(var(--white));
  --freespins-spins-button-border-color: rgba(var(--white), 0.5);
  --freespins-spins-button-span-color: rgb(var(--white));
  --freespins-count-down-background-color: rgba(var(--white), 0.7);
  --freespins-count-down-time-div-color: rgba(var(--semi-gray), 1);
  --freespins-count-down-time-color: rgb(10, 10, 10);
  /* ================== end ================== */

  /* ================== PackModalInfo ================== */
  --pack-modal-info-bonus-description-color: rgb(var(--white));
  --pack-modal-info-pack-subtitle-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== CasinoGridGames ================== */
  --casino-grid-games-button-top-background-color: rgb(var(--semi-dark));
  --casino-grid-games-icon-top-path-fill: rgb(var(--third));
  /* ================== end ================== */

  /* ================== Checkbox ================== */
  --checkbox-background-color: rgb(var(--gray));
  --checkbox-checked-background-color: rgb(var(--primary));
  --checkbox-checked-border-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== NotFoundData ================== */
  --not-found-data-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== DefaultGameImg ================== */
  --default-game-image-background-color: rgb(var(--dark-gray));
  --default-game-image-game-name-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== BonusCancelLoader ================== */
  --bonus-cancel-loader-background-color: rgba(var(--semi-dark), 0.8);
  /* ================== end ================== */

  /* ================== CircularLoader ================== */
  --circular-loader-wrapper-border-color: rgb(135, 135, 139);
  --circular-loader-inside-circular-border-color: transparent;
  /* ================== end ================== */

  /* ================== SkeletLoader ================== */
  --skelet-loader-wrapper-background-color: rgba(var(--white), 1);
  --skelet-loader-background-color: rgba(var(--soft-gray), 1);
  /* ================== end ================== */

  /* ================== Logo ================== */
  --logo-history-wrapper-border-color: rgb(var(--primary), 0.7);
  /* ================== end ================== */

  /* ================== LotteryHistoryCard ================== */
  --lottety-history-card-banner-arrow-border-color: rgb(var(--primary));
  --lottety-history-card-arrow-svg-path-fill: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== LotteryWinCard ================== */
  --lottery-win-card-title-ticket-color: rgb(var(--gray));
  --lottery-win-card-win-ticket-number-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== PopUpHeader mobile ================== */
  --pop-up-header-background-color: rgb(var(--dark));
  --pop-up-header-title-color: rgb(var(--third));
  --pop-up-header-close-button-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== ModalTitle ================== */
  --modal-title-color: rgb(var(--gray));
  --modal-title-svg-path-fill: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== Pagination ================== */
  --pagination-page-button-background-color: rgb(var(--dark));
  --pagination-page-button-color: rgb(var(--white));
  --pagination-step-button-background: transparent;
  --pagination-button-border: 2px solid rgba(198, 198, 200, 1);
  --pagination-button-second-text-color: rgba(135, 135, 139, 1);
  --pagination-button-text-color: rgba(135, 135, 139, 1);
  --pagination-active-button-border-color: rgba(198, 198, 200, 1);
  --pagination-active-button-background: transparent;
  --pagination-button-background: transparent;
  /* ================== end ================== */

  /* ================== ProgressBar ================== */
  --progress-bar-progress-wrapper-background-color: rgb(246, 246, 246);
  --progress-bar-progress-fill-background-color: rgb(var(--third));
  --progress-bar-title-color: rgb(var(--third));
  --progress-bar-percents-color: rgba(135, 135, 139, 1);
  /* ================== end ================== */

  /* ================== ProgressWagering ================== */
  --progress-wagering-wrapper-background-color: rgb(var(--semi-dark));
  --progress-wagering-progress-background-color: rgb(var(--primary));
  --progress-wagering-title-row-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== AccordionBase ================== */
  --accordion-base-dot-before-background-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== Selector ================== */
  --selector-item-color: rgba(var(--third), 1);
  --selector-item-placeholder-color: rgb(var(--gray));
  --selector-drop-down-wrapper-background-color: rgba(var(--white), 1);
  --selector-list-item-background-color: rgb(var(--dark));
  /* ================== end ================== */

  /* ================== Tabs ================== */
  --tabs-color: rgb(var(--white));
  --tabs-buttons-wrapper-background-color: rgb(var(--semi-dark-gray));
  --tabs-active-block-indicator-background-color: rgb(var(--third));
  --tabs-active-button-indicator-border-color: rgb(var(--primary));
  --tabs-active-button-indicator-background-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== Timer ================== */
  --timer-wrapper-color: rgb(var(--gray));
  --timer-time-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== TournamentCard ================== */
  --tournament-card-background-color: linear-gradient(
    74.18deg,
    #e9e9eb 34.1%,
    #b141f6 100.24%
  );
  --tournament-card-tournament-name-color: rgb(var(--third));
  --tournament-card-win-sum-color: rgb(135, 135, 139);
  --tournament-card-sum-color: rgb(var(--third));
  --tournament-card-sum-background-color: transparent;
  --tournament-card-sum-border-color: transparent;
  --tournament-card-info-background-color: transparent;
  --tournament-card-info-path-fill: rgb(var(--primary));
  --tournament-card-info-exclamation-mark-pathes-fill: rgb(var(--third));
  --tournament-card-play-background-color: rgb(var(--primary));
  --tournament-card-play-path-fill: rgb(var(--third));
  --tournament-card-count-down-background-color: rgb(199, 199, 199);
  --tournament-card-info-border-color: rgb(var(--primary));
  --tournament-card-play-border-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== TournamentPlayer ================== */
  --tournament-player-background-color: rgb(var(--semi-dark));
  --tournament-player-position-background-color: rgb(var(--semi-dark));
  --tournament-player-position-color: rgb(var(--semi-gray));
  --tournament-player-position-first-color: rgb(var(--primary));
  --tournament-player-position-first-border-color: var(--primary);
  --tournament-player-position-first-background-color: rgba(253, 216, 68, 0.25);
  --tournament-player-position-second-color: rgba(164, 202, 223, 1);
  --tournament-player-position-second-border-color: rgba(164, 202, 223, 1);
  --tournament-player-position-second-background-color: rgba(
    164,
    202,
    223,
    0.25
  );
  --tournament-player-position-third-color: rgba(255, 128, 37, 1);
  --tournament-player-position-third-border-color: rgba(255, 128, 37, 1);
  --tournament-player-position-third-background-color: rgba(255, 128, 37, 0.25);
  --tournament-player-avatar-background-color: rgb(var(--dark));
  --tournament-player-info-name-color: rgba(var(--third), 1);
  --tournament-player-info-sum-color: rgb(var(--semi-gray));
  --tournament-player-result-sum-color: rgb(var(--dark));
  --tournament-player-wrapper-coins-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== UTILS (USELESS) ================== */
  /* ================== LanguageItem ================== */
  --language-item-key-name-color: rgb(var(--gray));
  --language-input-text-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== LanguagesTable ================== */
  --languages-dev-table-color: rgb(var(--gray));
  --languages-dev-table-dev-name-span-color: rgb(var(--white));
  --languages-dev-table-key-name-color: rgb(var(--gray));
  --language-dev-table-input-text-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== ColorBlock ================== */
  --color-block-edit-block-background-color: ;
  /* ================== end ================== */

  /* ================== PAGES ================== */
  /* ================== CasinoProviderGames ================== */
  --casino-provider-games-title-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== CasinoProviders ================== */
  --casino-providers-title-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== HOME VideoBanner ================== */
  --video-banner-game-background-color: linear-gradient(
    0deg,
    rgb(var(--primary)) 0%,
    rgba(var(--dark), 0) 100%
  );
  --video-banner-game-wrapper-background-color: rgba(var(--semi-dark), 0.8);
  --video-banner-game-wrapper-color: rgb(var(--white));
  --video-banner-game-info-provider-name-color: rgb(var(--semi-gray));
  --video-banner-game-info-arrow-path-fill: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Jackpots ================== */
  --jackpots-background-color: rgb(var(--dark));
  --jackpots-background-slot-games-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== LandingAviatrix ================== */
  --landing-aviatrix-background-color: var(--landing-gradient);
  /* ================== end ================== */

  /* ================== BonusOnDepositLanding ================== */
  --bonus-on-deposit-landing-title: rgb(var(--white));
  /* ================== end ================== */

  /* ================== LOTTERY ================== */
  /* ================== LandingLottery ================== */
  --landing-lottery-background-color: rgb(var(--semi-dark));
  --landing-lottery-shadow-background-color: linear-gradient(
    360deg,
    rgba(var(--primary), 0) 70%,
    rgb(var(--semi-dark)) 100%
  );
  --landing-lottery-waves-path-fill: rgba(var(--white), 0.1);
  /* ================== end ================== */
  /* ================== Steps ================== */
  --steps-absolute-content-span-color: rgb(var(--third));
  --steps-title-color: rgb(var(--white));
  --steps-green-span-color: rgb(var(--primary));
  --steps-absolute-content-title-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Welcome ================== */
  --welcome-subtitle-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== PLAYMARKET ================== */
  --playmarket-wrapper-background-color: rgb(var(--white));
  --playmarket-complain-svg-fill: rgb(95, 99, 104);
  /* ================== end ================== */

  /* ================== Banner ================== */
  --banner-background-color: rgb(32, 33, 36);
  --banner-content-background-color: rgba(32, 33, 36, 0.8);
  --banner-subtitle-color: rgb(0, 161, 115);
  --banner-button-background-color: rgb(0, 161, 115);
  --banner-button-transparent-color: rgb(0, 161, 115);
  --banner-downloads-text-color: rgb(var(--white));
  --banner-downloads-block-span-color: rgb(var(--semi-gray));
  --banner-downloads-amount-color: rgb(var(--semi-gray));
  --banner-downloads-amount-before-color: rgb(60, 64, 67);
  --banner-able-to-download-svg-path-fill: rgb(154, 160, 166);
  --banner-able-to-download-span-color: rgb(154, 160, 166);
  --banner-age-restriction-p-first-color: rgb(32, 33, 36);
  --banner-age-restriction-p-first-background-color: rgb(var(--white));
  --banner-score-svg-path-fill: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Description ================== */
  --description-title-svg-path-fill: rgb(95, 99, 104);
  --description-text-color: rgb(95, 99, 104);
  --description-last-update-color: rgb(32, 33, 36);
  --description-update-date-color: rgb(95, 99, 104);
  --description-category-color: rgb(95, 99, 104);
  --description-category-border-color: rgb(218, 220, 224);
  --description-category-hover-color: rgb(32, 33, 36);
  --description-category-hover-background-color: rgb(247, 249, 248);
  /* ================== end ================== */

  /* ================== Footer ================== */
  --footer-border-color: rgb(232, 234, 237);
  --playmarket-footer-background-color: rgb(var(--white));
  --footer-text-content-color: rgb(95, 99, 104);
  --footer-links-color: rgb(95, 99, 104);
  /* ================== end ================== */

  /* ================== Header ================== */
  --playmarket-header-background-color: rgb(var(--white));
  --header-logo-span-color: rgb(95, 99, 104);
  --header-link-color: rgb(95, 99, 104);
  --header-link-active-color: rgb(0, 161, 115);
  --header-link-active-after-color: rgb(0, 161, 115);
  --header-icon-svg-path-fill: rgb(95, 99, 104);
  --header-icon-hover-background-color: rgb(249, 249, 249);
  --header-bottom-border: var(--balance-wrapper-border);
  /* ================== end ================== */

  /* ================== Reviews ================== */
  --reviews-title-svg-path-fill: rgb(95, 99, 104);
  --reviews-subtitle-color: rgb(95, 99, 104);
  --reviews-stars-amount-color: rgb(32, 33, 36);
  --reviews-amount-color: rgb(95, 99, 104);
  --reviews-device-border-color: rgb(218, 220, 224);
  --reviews-device-color: rgb(95, 99, 104);
  --reviews-device-hover-color: rgb(32, 33, 36);
  --reviews-device-hover-background-color: rgb(247, 249, 248);
  --reviews-device-svg-path-fill: rgb(95, 99, 104);
  --reviews-device-active-color: rgb(5, 100, 73);
  --reviews-device-active-background-color: rgb(230, 243, 239);
  --reviews-device-active-svg-path-fill: rgb(5, 100, 73);
  --reviews-progress-background-color: rgb(232, 234, 237);
  --reviews-progress-span-background-color: rgb(1, 135, 95);
  --reviews-all-color: rgb(1, 135, 95);
  /* ================== end ================== */

  /* ================== Review ================== */
  --review-user-photo-background-color: rgb(232, 234, 237);
  --review-text-color: rgb(95, 99, 104);
  --review-feedback-button-border-color: rgb(218, 220, 224);
  --review-feedback-button-hover-background-color: rgb(243, 243, 243);
  --review-feedback-button-active-background-color: rgb(213, 225, 223);
  --review-feedback-button-active-color: rgb(1, 135, 95);
  /* ================== end ================== */

  /* ================== Safety ================== */
  --safety-text-color: rgb(95, 99, 104);
  --safety-subtitle-color: rgb(95, 99, 104);
  --safety-options-border-color: rgb(218, 220, 224);
  --safety-more-info-button-color: rgb(1, 135, 95);
  /* ================== end ================== */

  /* ================== Slider ================== */
  --slider-slide-background-color: rgba(60, 64, 67, 0.3);
  --slider-arrow-background-color: rgb(var(--white));
  --slider-arrow-hover-background-color: rgb(247, 249, 248);
  /* ================== end ================== */

  /* ================== Stars ================== */
  --stars-star-fill: rgb(1, 135, 95);
  --stars-star-empty-fill: rgb(241, 243, 244);
  /* ================== end ================== */

  /* ================== Support ================== */
  --support-svg-path-fill: rgb(95, 99, 104);
  --support-show-options-button-hover-background-color: rgb(247, 249, 248);
  --support-content-link-color: rgb(32, 33, 36);
  --support-subtext-color: rgb(95, 99, 104);
  /* ================== end ================== */

  /* ================== Title ================== */
  --title-svg-path-fill: rgb(95, 99, 104);
  /* ================== end ================== */

  /* ================== Updates ================== */
  --updates-background-color: rgb(var(--white));
  --updates-text-color: rgb(95, 99, 104);
  /* ================== end ================== */

  /* ================== SLOTS ================== */
  /* ================== LandingSlots ================== */
  --landing-slots-background-color: var(--landing-gradient);
  --landing-slots-slot-title-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== SlotMachine ================== */
  --slot-machine-background-color: rgb(var(--semi-dark));
  --slot-machine-top-bonus-line-background-color: rgb(var(--gray));
  --slot-machine-game-zone-background-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== WheelLanding ================== */
  --wheel-landing-background-color: radial-gradient(
    circle,
    rgba(var(--third), 1) 0%,
    rgba(var(--semi-dark), 1) 100%
  );
  --wheel-landing-wheel-title-color: rgb(var(--white));
  --wheel-landing-circle-background-color: rgb(var(--primary));
  --wheel-text-color: rgb(var(--dark));
  /* ================== end ================== */

  /* ================== ElonPromoLanding ================== */
  --elon-promo-landing-item-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== PortalBonusInfo ================== */
  --portal-bonus-info-background-color: rgba(var(--semi-dark), 0.7);
  --portal-bonus-info-title-color: rgb(var(--white));
  --portal-bonus-info-subtitle-color: rgb(var(--white));
  --portal-bonus-info-big-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== WheelRoulette ================== */
  --wheel-roulette-wheel-title-color: rgb(var(--white));
  --wheel-roulette-circle-background-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== WheelLandingTwo IceWheel ================== */
  --ice-wheel-center-button-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== PopUpOfWheel ================== */
  --pop-up-of-wheel-background-color: rgba(0, 0, 0, 0.5);
  --pop-up-of-wheel-title-color: rgb(var(--white));
  --pop-up-of-wheel-description-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== LootBox ================== */
  --loot-box-title-color: rgb(var(--white));
  --loot-box-background-color: var(--landing-gradient);
  --loot-box-name-color: rgb(var(--white));
  --loot-box-chance-color: rgb(var(--semi-gray));
  --loot-box-stake-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== ProfileAccount ================== */
  --profile-account-progress-wrapper-background-color: rgba(233, 233, 235, 1);
  --profile-account-row-progress-info-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== HistoryBet ================== */
  --history-bet-background-color: rgb(var(--semi-dark));
  --history-bet-color: rgb(var(--white));
  --history-bet-date-color: rgb(var(--semi-gray));
  --history-bet-id-color: rgb(var(--gray));
  --history-bet-show-more-button-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== TicketDetails ================== */
  --ticket-details-background-color: rgba(var(--semi-dark), 0.8);
  --ticket-details-ticket-content-background-color: rgb(var(--semi-dark));
  --ticket-details-ticket-close-svg-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== DescriptionGameHistory ================== */
  --description-game-history-title-color: rgb(var(--gray));
  --description-game-history-info-title-color: rgb(var(--gray));
  --description-game-history-info-value-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== GameInfo ================== */
  --game-info-provider-info-color: rgb(var(--gray));
  --game-info-game-name-color: rgb(var(--primary));
  --game-info-info-block-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== Transactions ================== */
  --transactions-background-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Transaction ================== */
  --transaction-title-color: rgb(var(--dark));
  --transaction-date-and-id-color: rgb(var(--gray));
  --transaction-result-color: rgb(var(--white));
  --transaction-status-background-color: rgb(var(--red));
  --transaction-result-green-background-color: rgb(var(--primary));
  --transaction-result-yellow-background-color: rgb(var(--third));
  --transaction-result-red-color: rgb(var(--red));
  --transaction-result-green-color: rgb(var(--primary));
  --transaction-wagering-text-color: rgb(var(--gray));
  --transaction-details-color: rgba(var(--third), 1);
  /* ================== end ================== */

  /* ================== TransactionFilters ================== */
  --transaction-filters-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== PROMOTIONS ================== */
  /* ================== Applications ================== */
  --applications-background-color: rgb(var(--dark));
  --applications-title-color: rgb(var(--white));
  --applications-instruction-title-color: rgb(var(--white));
  --applications-instruction-block-color: rgba(var(--white), 0.8);
  --applications-instruction-block-title-color: rgb(var(--third));
  /* ================== end ================== */

  /* ================== BonusOnDeposit ================== */
  --promotions-bonus-on-deposit-background-color: rgb(var(--dark));
  --promotions-bonus-on-deposit-banner-content-color: rgb(var(--white));
  --promotions-bonus-on-deposit-banner-content-title-color: rgb(var(--gray));
  --promotions-bonus-on-deposit-step-position-background-color: rgba(
    var(--top-first-player-bg),
    0.5
  );
  --promotions-bonus-on-deposit-step-position-color: rgb(
    var(--top-first-player-bg)
  );
  --promotions-bonus-on-deposit-step-description-color: rgb(var(--white));
  --promotions-bonus-on-deposit-rules-title-color: rgb(var(--white));
  --promotions-bonus-on-deposit-rule-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== FAQ ================== */
  --faq-wrapper-background-color: rgb(var(--white));
  --faq-wrapper-color: rgb(var(--third));
  --faq-border-color: transparent;
  /* ================== end ================== */

  /* ================== FaqSection ================== */
  --faq-section-name-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== QuestionSection ================== */
  --faq-question-section-info-color: rgb(135, 135, 139);
  --faq-question-section-background-color: rgba(248, 248, 248, 1);
  --faq-question-section-active-color: rgb(var(--dark));
  --faq-question-section-answer-wrapper-background-color: rgba(
    248,
    248,
    248,
    1
  );
  /* ================== end ================== */

  /* ================== HomePromotions ================== */
  --home-promotions-wrapper-background-color: rgb(var(--semi-dark));
  --home-promotions-wrapper-color: rgba(var(--dark), 1);
  --home-promotions-wrapper-subtitle-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== MobileApps ================== */
  --mobile-apps-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== PromotionList ================== */
  --promotion-list-wrapper-item-background-color: rgb(var(--white));
  --promotion-list-wrapper-item-color: rgba(var(--dark), 1);
  /* ================== end ================== */

  /* ================== PromotionPacks ================== */
  --promotion-packs-background-color: rgb(var(--semi-dark));
  --promotion-packs-pack-wrapper-background-color: rgba(var(--soft-gray), 1);
  /* ================== end ================== */

  /* ================== PromotionsBanner ================== */
  --promotions-banner-logo-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== BattleProviders ================== */
  --battle-providers-background-color: rgb(var(--gray));
  --battle-providers-title-color: rgb(var(--gray));
  --battle-providers-rules-color: rgb(var(--gray));
  /* ================== end ================== */

  /* ================== Battles ================== */
  --battles-background-color: rgb(var(--dark));
  --battles-title-color: rgb(var(--white));
  --battles-battle-name: rgba(0, 0, 0, 1);
  --battles-battle-provider-name: rgba(0, 0, 0, 1);
  --battles-battle-in-house-name: rgb(var(--white));
  --battles-title-color: rgba(0, 0, 0, 1);
  --battles-tab-button-color: rgba(0, 0, 0, 1);
  --battle-participate-title-color: rgba(0, 0, 0, 1);
  --battle-participate-subtitle-color: rgba(0, 0, 0, 1);
  --battles-time-background: rgba(155, 35, 248, 1);
  --battles-banner-name-color: rgba(0, 0, 0, 1);
  --battles-banner-prize-pool-color: rgba(0, 0, 0, 1);
  --battles-banner-subTitle-color: rgba(135, 135, 139, 1);
  --battles-banner-title-color: rgba(0, 0, 0, 1);
  --battles-banner-button-color: rgba(0, 0, 0, 1);
  --battles-banner-text-color: rgba(0, 0, 0, 1);
  --battles-details-arrow-path: rgba(0, 122, 255, 1);
  --battles-details-color: rgba(0, 122, 255, 1);
  --battles-provider-name-border: 1px solid rgba(155, 35, 248, 1);
  --battles-provider-name-background: rgba(155, 35, 248, 1);
  --battles-tab-border: 2px solid rgba(198, 198, 200, 1);
  --battles-active-tab-background: rgba(155, 35, 248, 1);
  /* ================== end ================== */

  /* ================== Leaderboard ================== */
  --leaderboard-title-color: rgba(0, 0, 0, 1);
  --leaderboard-header-color: rgba(135, 135, 139, 1);
  --leaderboard-user-wager-color: rgba(0, 0, 0, 1);
  --leaderboard-user-id-color: rgba(0, 0, 0, 1);
  --leaderboard-user-icon-second-path: rgba(135, 135, 139, 1);
  --leaderboard-user-icon-first-path: rgba(0, 0, 0, 1);
  --leaderboard-user-icon-border: 2px solid rgba(198, 198, 200, 1);
  --leaderboard-user-background: rgba(246, 246, 246, 1);
  /* ================== end ================== */

  /* ================== Step ================== */
  --step-subtitle-color: rgba(0, 0, 0, 1);
  --step-title-color: rgba(155, 35, 248, 1);
  /* ================== end ================== */

  /* ================== ElonBattles ================== */
  --elon-battles-finish-time-background-color: rgb(var(--semi-dark));
  --elon-battles-finish-time-color: rgb(var(--gray));
  --elon-battles-finish-time-span-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== ProviderBattles ================== */
  --provider-battles-wrapper-tournament-background-color: rgb(var(--semi-dark));
  --provider-battles-title-color: rgb(var(--third));
  --provider-battles-lock-background-color: rgba(var(--semi-dark), 0.7);
  --provider-battles-lock-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== PromotionMonthly ================== */
  --promotion-monthly-background-color: rgb(var(--dark));
  --promotion-monthly-title-color: rgb(var(--white));
  --promotion-monthly-wrapper-claim-bonus-background-color: rgb(
    var(--semi-dark)
  );
  --promotion-monthly-descriptions-color: rgb(var(--third));
  --promotion-monthly-wrapper-classes-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== RankSystem ================== */
  /* ================== BannerRanked ================== */
  --banner-ranked-color: rgb(var(--white));
  --banner-ranked-title-span-color: rgb(var(--primary));
  /* ================== end ================== */

  /* ================== HowDoesItWork ================== */
  --how-does-it-work-title-color: rgb(var(--white));
  --how-does-it-work-subtitle-color: rgb(var(--semi-gray));
  --how-does-it-work-wrapper-step-background-color: rgb(var(--dark));
  --how-does-it-work-wrapper-step-title-color: rgb(var(--third));
  --how-does-it-work-description-color: rgb(var(--white));
  /* ================== end ================== */

  /* ================== Levels ================== */
  --levels-title-color: rgb(var(--white));
  --levels-title-span-color: rgb(var(--third));
  --levels-subtitle-color: rgb(var(--semi-gray));
  /* ================== end ================== */

  /* ================== LevelCard ================== */
  --level-card-content-wrapper-background-color: var(
    --gradient-bonus-on-deposit
  );
  --level-card-content-title-color: rgb(var(--white));
  --level-card-benefit-title-color: rgb(var(--third));
  --level-card-row-color: rgba(var(--white), 0.7);
  --level-card-result-color: rgba(var(--primary), 1);
  /* ================== end ================== */

  /* ================== RankBenefits ================== */
  --rank-benefits-wrapper-bonus-color: rgb(var(--white));
  --rank-benefits-descriptions-color: rgb(var(--semi-gray));
  --rank-benefits-descriptions-background-color: rgb(var(--semi-dark));
  /* ================== end ================== */

  /* ================== Promo Code ================== */
  --promo-code-modal-text: rgb(var(--dark));
  --promo-code-rule-color: rgba(0, 0, 0, 1);
  --promocode-row-background: rgba(246, 246, 246);
  --promocode-row-value-color: rgba(52, 199, 89, 1);
  --promocode-modal-background: rgba(255, 255, 255, 1);
  --promocode-search-text: rgba(0, 0, 0, 1);
  --promocode-search-label-color: rgba(135, 135, 139, 1);

  /* ================== END Promo Code ================== */
  /* ================== Confirmation ================== */
  --confirmation-pop-up-color: rgb(var(--third));
  /* ================== END Confirmation ================== */
  /* ================== Page Title ================== */
  --page-title-color: rgba(var(--third), 1);
  /* ================== END Page Title ================== */
  /* ================== Promo Code ================== */
  --promo-code-button-text-color: rgba(var(--third), 1);
  /* ================== END Promo Code ================== */

  /* ================== Arrow Button ================== */
  --arrow-button-svg-path-fill: rgb(135, 135, 139);
  --arrow-button-opened-svg-path-fill: rgb(135, 135, 139);
  /* ================== END Promo Code ================== */

  /* ================== SVGs ================== */
  --share-svg-color: rgb(var(--primary));
  --static-form-lock-svg-path-fill: rgb(199, 199, 199);
  --pop-up-header-svg-path-fill: rgb(135, 135, 139);
  /* ================== END SVGs ================== */

  /* ================== Tooltip ================== */
  --tooltip-text-color: rgb(var(--third));
  --tooltip-svg-gray-color: rgb(var(--semi-gray));
  /* ================== END Tooltip ================== */

  /* ================== MoneyComingPrize ================== */
  --money-coming-prize-button-color: rgb(var(--white));
  /* ================== END MoneyComingPrize ================== */
}
