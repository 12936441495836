.wrapperTournamentProvider {
  border-radius: var(--border-l);
  overflow: hidden;
}

.image {
  width: 100%;
  border-radius: var(--border-l);
  overflow: hidden;
  object-fit: cover;
}

.wrapperTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--indent-base);
}

.prizPool {
  padding: var(--indent-base);
  border: 2px solid var(--border-color-gray);
  background: var(--provider-tournament-priz-pool-background-color);
  color: var(--provider-tournament-priz-pool-color);
  border-radius: var(--border-l);
  text-align: center;
}

.providerName {
  font-size: 18px;
  color: var(--provider-tournament-provider-name-color);
  margin-bottom: var(--indent-base);
}

.finished {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--indent-base);
  font-size: 18px;
  border-radius: var(--border-s);
  color: var(--provider-tournament-finished-tournament-color);
  border: 2px solid var(--border-color-gray);
}

.gridItems {
  width: 100%;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 120px;
  gap: var(--indent-base);
  margin-bottom: var(--indent-base);
}

.verticalGrid {
  display: grid;
  grid-auto-flow: initial;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: var(--indent-base-half);
}

.openProviderGames {
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-s);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--provider-tournament-open-provider-games-color);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 120px;
}

.buttonPlay {
  margin-bottom: var(--indent-base);
}

.dash {
  margin-bottom: var(--indent-base);
  width: 100%;
  border-bottom: 2px solid var(--border-color-gray);
}

.blackContainer {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: var(--layout-lvl-4);
  background-color: var(--provider-tournament-black-container-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rules {
  width: 90%;
  height: 80%;
  background-color: var(--provider-tournament-rules-background-color);
  border-radius: var(--border-l);
  padding: var(--indent-base);
  border: 2px solid var(--border-color-gray);
}

.content {
  height: calc(100% - var(--indent-base) * 3);
  overflow-y: auto;
}

.title {
  font-size: 18px;
  color: var(--provider-tournament-title-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--indent-base);
}

.text {
  color: var(--provider-tournament-text-color);
  font-size: 1rem;
  margin-bottom: var(--indent-base);
}

.relative {
  position: relative;
  border-radius: var(--border-l);
  margin-bottom: var(--indent-base);
}

.arrowLeft,
.arrowRight {
  top: 0;
  position: absolute;
  width: 50px;
  height: 100%;
  background-color: var(--provider-tournament-arrow-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowRight {
  left: initial;
  right: 0;
}

.arrowRight path,
.arrowLeft path {
  fill: var(--provider-tournament-arrow-path-fill);
}

.rotateLeft {
  transform: rotate(-90deg);
}

.rotateRight {
  transform: rotate(90deg);
}

.marginL {
  margin-left: var(--indent-base);
}

@media (min-width: 768px) {
  .rules {
    max-width: 550px;
  }
}